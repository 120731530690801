<template>
  <div>
    <section class="section-box section-box--black section-box--first">
      <div class="wrapper">
        <v-main-info :data="mainInfoData" class="main-info--no-visual"></v-main-info>
      </div>
    </section>
    <article class="section-box section-box--visible">
      <div class="wrapper career-show-container">
        <div class="simple-text" v-html="careersItem.content"></div>
        <div class="career-recruiter" v-if="careersItem.manager && Object.keys(careersItem.manager).length">
          <img class="career-recruiter__img" :src="avatar" width="180" height="180" />
          <div class="career-recruiter__data">
            <p class="career-recruiter__title">{{ careersItem.manager.full_name }}</p>
            <p class="career-recruiter__position">{{ careersItem.manager.job_position }}</p>
            <div v-if="careersItem.manager.phone"><a class="career-recruiter__phone" :href="`tel:${careersItem.manager.phone}`">{{ careersItem.manager.phone }}</a></div>
            <div v-if="careersItem.manager.email"><a class="career-recruiter__email" :href="`mailto:${careersItem.manager.email}`">{{ careersItem.manager.email }}</a></div>
          </div>
        </div>
      </div>
    </article>
    <section class="section-box section-box--highlighted">
      <div class="wrapper">
        <v-careers-list
            :list="careersList"
            :filter-department="currentDepartmentTab"
            :filter-location="currentLocationTab">
        </v-careers-list>
      </div>
    </section>
  </div>
</template>
<script>

import * as api from '@/helpers/Api'
import MainInfo from "@/components/layout/Main-info/Main-info";
import CareersList from "@/components/layout/Careers/Careers-list";
import {eventBus} from "@/main";
export default {
  name: "Career-single",
  metaInfo: {
    title: 'Careers – ITernal group'
  },
  data() {
    return {
      careersItem: {},
      mainInfoData: {
        title: '',
        subTitle: '',
        backLink: 'Careers'
      },
      filters: {},
      careersList: [],
      currentDepartmentTab: 'All',
      currentLocationTab: 'All'
    }
  },
  components: {
    'v-main-info': MainInfo,
    'v-careers-list': CareersList
  },
  computed: {
    avatar() {
      return this.careersItem.manager.avatar ? `https://www.rademade.com/${this.careersItem.manager.avatar}` : 'https://via.placeholder.com/360x360'
    }
  },
  methods: {
    async getData(slug) {
      await api.getSingleVacancy(slug).then(result => {
        if (result) {
          this.careersItem = result.data;
          this.mainInfoData.title = result.data.name;
          this.mainInfoData.subTitle = result.data.short_description;
        }
      })
      await api.getVacancies().then(result => {
        if (result) {
          this.careersList = result.data;
          this.careersListAll = result.data;
        }
      })
    },
    byDepartment(item, value) {
      return value ? item.department.name === value : false
    },
    byLocation(item, value) {
      return item.location && item.location.match(value)
    },
    filterByAll({department, location}) {
      if (department) {
        this.filters.department = department
      }
      if (location) {
        this.filters.location = location
      }
      this.careersList = this.careersListAll.filter(
          item => {
            const matchedByDepartment = (this.filters.department && this.filters.department !== 'All') ? this.byDepartment(item, this.filters.department) : true;
            const matchedByLocation = (this.filters.location && this.filters.location !== 'All') ? this.byLocation(item, this.filters.location) : true;
            return matchedByDepartment && matchedByLocation
          }
      )
    }
  },
  created() {
    this.getData(this.$route.params.slug)
  },
  mounted() {
    eventBus.$on('filteringByDepartment', value => {
      this.currentDepartmentTab = value
      this.filterByAll({department: value})
    })
    eventBus.$on('filteringByLocation', value => {
      this.currentLocationTab = value
      this.filterByAll({location: value})
    })
  },
  beforeRouteUpdate (to, from, next) {
    if (to.params.slug !== this.$route.params.slug) {
      this.getData(to.params.slug)
      next()
    } else {
      next()
    }
  }
}
</script>

<style lang="scss">
.career-show-container {
  position: relative;
}

.career-recruiter {
  position: absolute;
  top: -350px;
  right: 15px;
  padding: 45px 50px 55px;
  text-align: center;
  background-color: #F1F1F1;

  @media (max-width: $viewport-desktop) {
    display: flex;
    position: relative;
    top: 0;
    right: 0;
    margin-top: 50px;
    padding: 30px 45px;
    text-align: left;
  }

  @media (max-width: $viewport-mobile) {
    display: block;
    margin-top: 50px;
    padding: 30px 30px 40px;
    text-align: center;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 215px;
    background: url("~@/assets/images/career-show.png");
    background-size: cover;

    @media (max-width: $viewport-desktop) {
      height: 100%;
      width: 160px;
    }

    @media (max-width: $viewport-mobile) {
      height: 130px;
      width: 100%;
    }
  }
}

.career-recruiter__img {
  position: relative;
  z-index: 1;
  display: inline-block;
  border: 3px solid #fff;
  margin-bottom: 20px;
  object-fit: cover;

  @media (max-width: $viewport-desktop) {
    margin: 0 40px 0 0;
    width: 150px;
    height: 150px;
  }

  @media (max-width: $viewport-mobile) {
    margin: 0 0 20px 0;
    width: 120px;
    height: 120px;
  }
}

.career-recruiter__data {
}

.career-recruiter__title {
  margin-bottom: 6px;
  font-family: Visuelt Pro;
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 1.357142857;

  @media (max-width: $viewport-desktop) {
  }
}

.career-recruiter__position {
  margin-bottom: 35px;
  font-size: 0.875rem;
}

.career-recruiter__phone {
  display: inline-block;
  margin-bottom: 25px;
  font-weight: 600;
  font-size: 0.9375rem;
  color: #000000;
  border-bottom: 1px solid rgba(0,0,0,.3);
  transition: border-bottom-color .2s ease-in-out;

  &:hover {
    border-bottom-color: transparent;
  }

  @media (max-width: $viewport-desktop) {
    margin-bottom: 15px;
  }

  @media (max-width: $viewport-mobile) {
    font-size: 0.875rem;
  }
}

.career-recruiter__email {
  font-weight: 600;
  font-size: 0.9375rem;
  color: #FF3500;
  border-bottom: 1px solid rgba(255,53,0,.3);
  text-overflow: ellipsis;
  transition: border-bottom-color .2s ease-in-out;

  &:hover {
    border-bottom-color: transparent;
  }

  @media (max-width: $viewport-mobile) {
    font-size: 0.875rem;
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>
