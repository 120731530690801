<template>
  <div>
    <v-careers-filter :locations="locations" :departments="departments"></v-careers-filter>
    <router-link :to="{name: 'Careers-single', params: { slug: item.alias }}" v-for="item in list" :key="item.alias" class="careers-item">
      <span class="careers-item__title-data">
        <span class="careers-item__title" v-if="item.name" v-html="item.name"></span>
        <span class="careers-item__link">View details</span>
      </span>
      <span class="careers-item__info">
        <span class="careers-item__description" v-if="item.short_description || item.content" v-html="item.short_description || item.content"></span>
        <span class="careers-item__requirements">
          <span class="careers-item-requirements__item careers-item-requirements-item" v-if="item.location">
            <v-icon-location class="careers-item-requirements-item__img"></v-icon-location>
            {{ item.location }}
          </span>
          <span class="careers-item-requirements__item careers-item-requirements-item" v-if="item.experience">
            <v-icon-target class="careers-item-requirements-item__img"></v-icon-target>
              {{ item.experience }} Experience
          </span>
          <span class="careers-item-requirements__item careers-item-requirements-item" v-if="item.english_level">
            <v-icon-comment class="careers-item-requirements-item__img"></v-icon-comment>
            {{ item.english_level }} English
          </span>
        </span>
      </span>
    </router-link>
    <v-careers-no-result
        v-if="dataLoaded && list && !list.length"
        :filter-location="filterLocation"
        :filter-department="filterDepartment"
    />
  </div>
</template>

<script>
//Icons
import IconLocation from '@/assets/icons/location.svg'
import IconTarget from '@/assets/icons/target.svg'
import IconComment from '@/assets/icons/comment.svg'
//Components
import CareersFilter from "@/components/layout/Careers/Careers-filter";
import CareersNoResult from '@/components/layout/Careers/Careers-no-results'
//Helpers
import * as api from "@/helpers/Api";

export default {
  name: "Careers-list",
  props: {
    list: Array,
    filterLocation: String,
    filterDepartment: String,
    dataLoaded: Boolean
  },
  data() {
    return {
      locations: [],
      departments: []
    }
  },
  components: {
    'v-icon-location': IconLocation,
    'v-icon-target': IconTarget,
    'v-icon-comment': IconComment,
    'v-careers-filter': CareersFilter,
    'v-careers-no-result': CareersNoResult
  },
  methods: {
    async getFilters() {
      await api.getDepartments().then(result => {
        if (result) {
          this.departments = result.data
        }
      });
    }
  },
  created() {
    this.getFilters();
  },
}
</script>

<style lang="scss">
.careers-item {
  display: flex;
  padding: 50px 90px;
  color: #000;
  box-shadow: 0 -1px 0 0 #d4d4d4;
  transition: all .2s ease-in-out;

  @media (max-width: $viewport-tablet) {
    padding: 35px 50px;
  }

  @media (max-width: $viewport-mobile) {
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 1px;
    padding: 35px 25px 80px;
    background: #fff;
    box-shadow: 0 1px 0 0 #d4d4d4;
  }

  &:hover {
    background-color: #FFFFFF;
    box-shadow: 0 40px 64px #EBEBEB;

    @media (max-width: $viewport-mobile) {
      box-shadow: 0 1px 0 0 #d4d4d4;
    }

    .careers-item__link {
      opacity: 1;
    }
  }
}

.careers-item__title-data {
  position: relative;
  width: 50%;
  padding-right: 50px;
  padding-bottom: 30px;
  box-sizing: border-box;

  @media (max-width: $viewport-tablet) {
    width: 40%;
  }

  @media (max-width: $viewport-mobile) {
    position: static;
    width: 100%;
    padding-bottom: 15px;
  }
}

.careers-item__title {
  display: block;
  font-family: Visuelt Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 1.357142857;

  @media (max-width: $viewport-tablet-small) {
    font-size: 1.5rem;
  }
}

.careers-item__link {
  position: absolute;
  bottom: 0;
  left: 0;
  padding-right: 50px;
  font-weight: bold;
  font-size: 0.75rem;
  opacity: 0;
  transition: opacity .2s ease-in-out;

  @media (max-width: $viewport-mobile) {
    opacity: 1;
    bottom: 30px;
    left: 25px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 12px;
    height: 12px;
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.016 4.926a.73.73 0 00-.228-.527L7.618.229C7.451.069 7.28 0 7.102 0c-.406 0-.698.286-.698.673 0 .203.082.374.21.501l1.427 1.448 1.841 1.682-1.472-.09H.717c-.425 0-.717.293-.717.712 0 .412.292.704.717.704h7.694l1.472-.088-1.84 1.682L6.613 8.67a.699.699 0 00-.21.501c0 .388.293.673.699.673.178 0 .35-.07.501-.216l4.184-4.183a.728.728 0 00.228-.52z' fill='%23000'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }
}

.careers-item__info {
  width: 50%;

  @media (max-width: $viewport-tablet) {
    width: 60%;
  }

  @media (max-width: $viewport-mobile) {
    width: 100%;
  }
}

.careers-item__description {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 0.875rem;
  line-height: 2.714285714;
  max-height: 74px;

  @media (max-width: $viewport-mobile) {
    font-size: 0.75rem;
    line-height: 2;
    max-height: 48px;
  }
}

.careers-item__requirements {
  display: block;
  margin-top: 20px;
}

.careers-item-requirements__item {

}

.careers-item-requirements-item {
  position: relative;
  display: inline-block;
  margin-right: 20px;
  padding-left: 20px;
  color: #797979;
  font-weight: 500;
  font-size: 0.75rem;

  &:last-child {
    margin-right: 0;
  }
}

.careers-item-requirements-item__img {
  position: absolute;
  top: 2px;
  left: 0;
  width: 14px;
  height: 14px;
}


</style>
