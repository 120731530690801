<template>
  <div>
    <h2 class="section-title section-title">Open positions</h2>
    <div class="careers-filter">
      <div class="careers-filter__item careers-filter-item" v-if="departments && departments.length">
        <p class="careers-filter-item__title" v-if="false">Type</p>
        <ul class="careers-filter-item__options careers-filter-item-options">
          <li class="careers-filter-item-options__item"
              @click="filteringByDepartment('All')"
              :class="{'careers-filter-item-options__item--current': currentDepartmentTab === 'All'}">
            <span class="careers-filter-item-options__item-text">All</span></li>
          <li class="careers-filter-item-options__item"
              v-for="item in actualDepartments"
              :key="item.name"
              @click="filteringByDepartment(item.name)"
              :class="{'careers-filter-item-options__item--current': currentDepartmentTab === item.name}">
            <span class="careers-filter-item-options__item-text">{{ item.name }}</span>
          </li>
        </ul>
      </div>
      <div class="careers-filter__item careers-filter-item" v-if="locations && locations.length">
        <p class="careers-filter-item__title">Location</p>
        <ul class="careers-filter-item__options careers-filter-item-options">
          <li class="careers-filter-item-options__item" @click="filteringByLocation('All')" :class="{'careers-filter-item-options__item--current': currentLocationTab === 'All'}">
            <span class="careers-filter-item-options__item-text">All</span>
          </li>
          <li class="careers-filter-item-options__item" v-for="item in locations" :key="item" @click="filteringByLocation(item)" :class="{'careers-filter-item-options__item--current': currentLocationTab === item}">
            <span class="careers-filter-item-options__item-text">{{ item }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/main'

export default {
  name: "Careers-filter",
  props: {
    locations: Array,
    departments: Array
  },
  data() {
    return {
      currentDepartmentTab: 'All',
      currentLocationTab: 'All'
    }
  },
  computed: {
    actualDepartments() {
      return this.departments.filter(item => item.vacancies_count)
    }
  },
  methods: {
    filteringByDepartment(value) {
      this.currentDepartmentTab = value;
      eventBus.$emit('filteringByDepartment', value)
    },
    filteringByLocation(value) {
      this.currentLocationTab = value;
      eventBus.$emit('filteringByLocation', value)
    }
  }
}
</script>

<style lang="scss">
.careers-filter {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 65px;

  @media (max-width: $viewport-tablet) {
    margin-bottom: 0;
  }

  @media (max-width: $viewport-mobile) {
    margin-bottom: 20px;
  }
}

.careers-filter__item {
  //margin-bottom: 50px;
  cursor: pointer;

  &:first-child {
    min-width: calc(50% - 30px);
    margin-right: 30px;

    @media (max-width: $viewport-tablet) {
      min-width: auto;
      width: 100%;
      margin-right: 0;
    }
  }

  &:last-child {
    flex: 1;
  }

  @media (max-width: $viewport-mobile) {
    margin-bottom: 30px;
  }
}

.careers-filter-item__title {
  display: block;
  margin-bottom: 25px;
  font-family: Visuelt Pro;
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 1.357142857;

  @media (max-width: $viewport-mobile) {
    margin-bottom: 15px;
    font-size: 1.5rem;
  }
}
.careers-filter-item-options {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
  &::-webkit-scrollbar {
    width: 0;
    background: transparent; /* Chrome/Safari/Webkit */
  }
  @media (max-width: $viewport-tablet-small) {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.careers-filter-item-options__item {
  flex-shrink: 0;
  margin-right: 1px;
  margin-bottom: 1px;


  &:hover {
    .careers-filter-item-options__item-text {
      background-color: #fff;
    }
  }

  &:first-child {
    @media (max-width: $viewport-tablet-small) {
      padding-left: 15px;
    }
  }

  &:last-child {
    margin-right: 0;
    @media (max-width: $viewport-tablet-small) {
      padding-right: 15px;
    }
  }

  &-text {
    display: block;
    padding: 25px 20px;
    font-family: Visuelt Pro;
    font-size: 0.9375rem;
    color: #707070;
    line-height: 1.266666667;
    background-color: #ececec;
    transition: background-color .2s ease-in-out;
  }

  &--current {
    .careers-filter-item-options__item-text {
      color: #000;
      font-weight: 500;
      background-color: #fff;
    }
  }
}


</style>
