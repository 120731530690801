<template>
  <div class="careers-no-results">
    <v-icon-no-results class="careers-no-results__icon"/>
    <p class="careers-no-results__title">No results found</p>
    <p class="careers-no-results__text" v-html="resultText"></p>
  </div>
</template>

<script>
import IconNoResults from '@/assets/icons/no-results.svg'
export default {
  name: "Careers-no-results",
  props: {
    filterDepartment: String,
    filterLocation: String
  },
  computed: {
    resultText() {
      return `Sorry, your filters for type (<strong>${this.filterDepartment}</strong>), location (<strong>${this.filterLocation}</strong>) did not match any positions`
    }
  },
  components: {
    'v-icon-no-results': IconNoResults
  }
}
</script>

<style lang="scss">
.careers-no-results {
  padding-top: 147px;
  padding-bottom: 92px;
  text-align: center;
  border-top: 1px solid #D9D9D9;

  @media (max-width: $viewport-tablet) {
    padding-top: 80px;
    padding-bottom: 20px;
  }
}
.careers-no-results__icon {
  width: 154px;
  height: 154px;
  margin-bottom: 56px;

  @media (max-width: $viewport-tablet) {
    width: 100px;
    height: 100px;
    margin-bottom: 30px;
  }
}

.careers-no-results__title {
  margin-bottom: 12px;
  font-family: Visuelt Pro;
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 1.357142857;

  @media (max-width: $viewport-mobile) {
    margin-bottom: 15px;
    font-size: 1.5rem;
  }
}
.careers-no-results__text {
  font-family: Visuelt Pro;
  font-size: .75rem;
  color: #707070;
  line-height: 1.266666667;
}
</style>
